.editable-table {
  margin-top: 20px;
}

.editable-table .tabulator-cell.draft {
  color: red;
}

.editable-table .tabulator-cell.cell-link {
  color: #22bffd;
}

.editable-table .tabulator-cell.pre-wrap {
  white-space: pre-wrap;
}

.editable-table .tabulator-cell.overflow-visible {
  overflow: visible;
}

.editable-table .tabulator-cell .select-container {
  white-space: normal;
}

.editable-table .tabulator-cell.cell-link:hover {
  color: #23527c;
}

.editable-table .actions-btn {
  height: 14px;
  margin-right: 0px;
  cursor: pointer;
}

.editable-table .title-filter {
  margin-top: 10px;
  display: inline-block;
}

.editable-table h4.with-title-filter b {
  display: block;
}

.editable-table .title-filter label {
  margin: 0 10px 0 0;
  font-weight: 500;
  font-family: inherit;
  position: relative;
  font-size: 16px;
}

.editable-table .title-filter .Select {
  display: inline-block;
  width: 300px;
  font-size: 14px;
}

.editable-table h4.with-title-filter .btn {
  position: relative;
}

.editable-table h4.with-title-search form {
  margin-top: 10px;
}

.editable-table h4.with-title-search form input[type=text] {
  font-size: 14px;
  width: 400px;
  height: 33px;
  padding: 0 5px;
}