.tabulator-edit-select-list {
  z-index: 2000 !important;
}

.layout-nodes-modal .modal-dialog {
  width: 1300px;
}

.layout-themes-modal .modal-dialog {
  width: 1400px;
}

.tabulator-cell.theme-photo {
  min-height: 150px;
}
