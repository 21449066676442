.tabulator-edit-select-list {
  z-index: 2000 !important;
}

.backgrounds-modal .modal-dialog {
  width: 900px;
}

.tabulator-cell.background-photo {
  min-height: 150px;
}

.tabulator-cell.background-photo img {
  margin-bottom: 5px;
}