.version-tag {
  font-size: 10px;

  position: fixed;
  z-index: 100;
  bottom: 20px;
  left: 20px;

  margin: 0;
  padding: 8px 8px 4px;

  text-align: center;
  pointer-events: none;

  color: #545454;
  border: 1px solid #eaeaea;
  background: rgba(255, 255, 255, 0.8);
}

.version-tag__heading {
  font-weight: bold;

  margin: 0;
}

@media only screen and (max-width: 768px) {
  .version-tag {
    display: none;
  }
}
