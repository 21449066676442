.navigation {
  position: relative;
  z-index: 5;

  display: flex;

  width: 100%;
  min-height: 48px;

  background-color: #2d4a6a;
}

.navigation a {
  text-decoration: none;
}

.navigation__container {
  display: flex;

  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
  padding-right: 60px;
  padding-left: 60px;

  flex-flow: row nowrap;
  flex-grow: 1;
  justify-content: flex-start;
}

.navigation__header {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;

  display: flex;

  height: 48px;
  padding: 0 15px;

  background-color: #2d4a6a;

  align-items: center;
  justify-content: space-between;
}

.navigation__logo {
  display: flex;

  align-items: center;
}

.navigation__logo img {
  width: 79px;
  height: 22px;
  margin-top: 8px;
}

.navigation__main {
  display: flex;

  margin-bottom: 0px;
  margin-left: 55px;
  padding: 0;

  align-items: stretch;
}

.navigation__user {
  display: flex;

  margin-right: 0;
  margin-bottom: 0;
  margin-left: auto;
  padding: 0px;

  list-style-type: none;

  align-items: stretch;
  justify-self: end;
}

.navigation__item {
  position: relative;

  display: flex;
  overflow: visible;

  margin: 0 12px;
  padding: 0px;

  cursor: pointer;
  transition: all 0.4s ease-in;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;

  color: #ffffff;

  align-items: center;
}

.navigation__item:nth-child(1) {
  margin-left: 0px;
}

.navigation__item:nth-last-child(1) {
  margin-right: 0px;
}

.navigation__item:after {
  position: absolute;
  bottom: 0px;
  left: 0px;

  display: block;

  width: 100%;
  height: 3px;

  content: "";
  transition: transform 0.2s ease-in;
  transform: scale(0);

  background-color: #22bffd;
}

.navigation__item:hover {
  color: rgba(255, 255, 255, 0.8);
}

.navigation__item:hover .navigation__link {
  color: rgba(255, 255, 255, 0.8);
}

.navigation__item:hover:after {
  transform: scale(1.25);
}

.navigation__hide-desktop {
  display: none;
}

.navigation__item--admin:after {
  content: none;
}

.navigation__link {
  transition: all 0.4s ease-in;

  color: #ffffff;
}

.navigation__link:focus {
  color: #ffffff;
}

.navigation__link--active:after {
  position: absolute;
  bottom: 0px;
  left: 0px;

  display: block;

  width: 100%;
  height: 3px;

  content: "";
  transition: transform 0.2s ease-in;
  transform: scale(1.25);

  background-color: #22bffd;
}

.navigation__settings {
  position: relative;

  display: flex;
  overflow: visible;

  margin: 0 12px;
  padding: 0px;

  cursor: pointer;
  transition: all 0.4s ease-in;
  vertical-align: middle;
  text-decoration: none;

  color: #ffffff;

  align-items: center;
  flex-shrink: 0;
}

.navigation__settings .fa {
  margin-left: 7px;

  color: #ffffff;
}

.navigation__settings .caret {
  display: none;
}

.navigation__settings img {
  width: 20px;
  height: 20px;
}

.navigation__settings:after {
  position: absolute;
  bottom: 0px;
  left: 0px;

  display: block;

  width: 100%;
  height: 3px;

  content: "";
  transition: transform 0.2s ease-in;
  transform: scale(0);

  background-color: #22bffd;
}

.navigation__settings--item {
  display: flex;

  margin: 0;
  padding: 10px;

  color: #000000;
}

.navigation__settings:hover:after,
.navigation__settings--active:after {
  transform: scale(1.25);
}

.navigation__settings div > a:first-child {
  color: #ffffff;
}

.navigation__settings div > a:first-child:hover {
  color: rgba(255, 255, 255, 0.8);
}

.navigation__settings--item:hover {
  text-decoration: none;

  color: black;
  background-color: #f3f4f6;
}

.navigation__settings--item--active {
  background-color: #f3f4f6;
}

.header-lab-select {
  width: 200px;
  top: 5px;
  left: -5px;
}

.hamburger-menu {
  width: 24px;
  height: 24px;

  cursor: pointer;
  transition: 0.4s ease-in-out;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.hamburger-menu span {
  position: absolute;
  left: 0;

  display: block;

  width: 100%;
  height: 2px;

  transition: 0.25s ease-in-out;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);

  opacity: 1;
  border-radius: 9px;
  background: #ffffff;
}

.hamburger-menu span:nth-child(1) {
  top: 4px;
}

.hamburger-menu span:nth-child(2) {
  top: 10px;
}

.hamburger-menu span:nth-child(3) {
  top: 10px;
}

.hamburger-menu span:nth-child(4) {
  top: 16px;
}

.hamburger-menu--active span:nth-child(1) {
  top: 10px;
  left: 50%;

  width: 0;
}

.hamburger-menu--active span:nth-child(2) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.hamburger-menu--active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.hamburger-menu--active span:nth-child(4) {
  top: 18px;
  left: 50%;

  width: 0;
}

@media only screen and (max-width: 1024px) {
  .navigation__item--admin {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    padding-top: 108px;
  }

  .navigation {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    height: 48px;

    transition: height 0.4s ease-in;
  }

  .navigation--active {
    height: 370px;
  }

  .navigation__container {
    flex-direction: column;

    margin: 48px auto;
    padding: 15px;

    -webkit-transform: translateY(-370px);
            transform: translateY(-370px);

    background-color: #2d4a6a;

    justify-content: flex-start;
  }

  .navigation__container--active {
    height: 100vh;

    transition: all 0.4s ease-in-out;
    transform: translateY(0px);
  }

  .navigation__main {
    flex-direction: column;

    height: auto;
    margin: 0;

    transition: all 0.4s ease-in-out;

    align-items: flex-start;
    justify-content: flex-start;
  }

  .navigation__item {
    width: 100%;
    margin: 0;
  }

  .navigation__item:after {
    content: none;
  }

  .navigation__item:hover {
    color: #22bffd;
  }

  .navigation__item:hover .navigation__link {
    font-weight: bold;

    color: #22bffd;
  }

  .navigation__link {
    width: 100%;
    padding: 10px 0px;

    text-align: left;
  }

  .navigation__link--active {
    font-weight: bolder;
  }

  .navigation__link--active:after {
    content: none;
  }

  .navigation__user {
    flex-direction: column;

    margin: 0;

    text-align: center;
  }

  .navigation__user .navigation__item:last-child {
    padding-top: 10px;
  }

  .navigation__hide-desktop {
    display: inherit;
  }

  .navigation__hide-mobile {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  .header-studio-select {
    display: none;
  }
}

@media only screen and (max-width: 768px) and (max-height: 425px) {
  .navigation__container {
    flex-direction: row;
  }

  .navigation__user {
    margin-left: 75px;
  }
}
