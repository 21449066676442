#login__index .right-onboarding-container {
  display: flex;
  flex-direction: column;

  padding: 20px 60px;

  align-items: center;
  justify-content: flex-end;
}

#login__index .sign-in-form {
  width: 100%;
  margin-bottom: 80px;
}

#login__index .password-options {
  display: flex;

  align-items: flex-start;
  justify-content: space-between;
}

#login__index .password-options .checkbox {
  margin: 0;
}

#login__index .forgot-password-link {
  cursor: pointer;
}

#login__index .login-btn {
  width: 328px;
}

#login__index .no-account-link {
  margin-top: 40px;
}

#login__index #forgot-password-modal input {
  width: 100%;
  max-width: 300px;
  margin: 0 auto 10px auto;
}

#login__index #forgot-password-modal button {
  width: 132px;
}

#login__index .modal-header h4 {
  margin-bottom: 20px;
}

#login__index .modal-footer {
  padding-bottom: 0;
}

#login__index .container-fluid.error {
  transform: translate3d(0, 0, 0);
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;

  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.form-group.has-error input {
  border-color: #a94442;
}

@media only screen and (max-width: 830px) {
  #login__index .right-onboarding-container {
    padding: 40px 20px 20px 20px;
  }
}
