#not_found_404_index .error-container {
  line-height: 26px;

  margin-top: 60px;
  padding: 60px;

  text-align: center;

  border: 1px dashed #cccccc;
}

#not_found_404_index .error-container img {
  width: 80%;
  max-width: 450px;
  margin: 40px 0;
}
