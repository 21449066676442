/* Typography*/
@font-face {
  font-family: "Helvetica Roman";
  font-weight: normal;
  font-style: normal;

  src: url("../fonts/helvetica-roman.woff2") format("woff2"), url("../fonts/helvetica-roman.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Light";
  font-weight: normal;
  font-style: normal;

  src: url("../fonts/helvetica-light.woff2") format("woff2"), url("../fonts/helvetica-light.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Bold";
  font-weight: normal;
  font-style: normal;

  src: url("../fonts/helvetica-bold.woff2") format("woff2"), url("../fonts/helvetica-bold.woff") format("woff");
}

@font-face {
  font-family: "quendamedium";
  font-weight: normal;
  font-style: normal;

  src: url("../fonts/quenda-medium-webfont.woff2") format("woff2"), url("../fonts/quenda-medium-webfont.woff") format("woff");
}

/* Animations */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes spinner-fade {
  to {
    opacity: 1;
  }
}

@keyframes spinner-fade {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes spinner-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes spinner-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  25% {
    -webkit-transform: scale(1.55);
            transform: scale(1.55);

    background: #cccccc;
  }
}

@keyframes expand {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  25% {
    -webkit-transform: scale(1.55);
            transform: scale(1.55);

    background: #cccccc;
  }
}

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body {
  font-family: "Helvetica Roman", "Arial", "sans-serif";

  color: #4a4a4a;
}

a {
  color: #22bffd;
}

b,
strong {
  font-family: "Helvetica Bold";
}

.bold-font {
  font-family: "Helvetica Bold", "Arial", "sans-serif";
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
}

/* Layout*/
.container {
  width: 100%;
  max-width: 1480px;
  padding: 0 60px;

  -webkit-animation: fadein 0.4s;
          animation: fadein 0.4s;
}

/* Icons */
i[class^="icon-"],
a[class^="icon-"],
button[class^="icon-"] {
  display: inline-block;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.icon-csv-file {
  width: 36px;
  height: 41px;

  background-image: url("../images/icon-csv-file.png");
}

.school-icon-bg {
  background: url("../images/icon_bg.jpg") no-repeat;
  background-size: cover;
}

.icon-add-blue {
  width: 38px;
  height: 37px;

  background-image: url("../images/icon_blue_add.png");
}

.icon-star {
  width: 24px;
  height: 24px;

  -webkit-animation: fadein 0.2s;
  animation: fadein 0.2s;

  background-image: url("../images/icon-star.png");
}

.icon-star--empty {
  background-image: url("../images/icon-star-empty.png");
}

#nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  padding: 20px 15px;
}

#logo {
  height: 22px;
}

.user-thumb {
  width: 24px;
  height: 24px;
}

.notifications-icon {
  position: relative;

  cursor: pointer;
}

.notifications-icon .notifications-number {
  font-family: "Helvetica Roman", "Arial", "sans-serif";
  font-size: 11px;

  position: absolute;
  top: -6px;
  left: 18px;

  min-width: 15px;
  min-height: 15px;
  padding: 2px 6px 0 5px;

  text-align: center;

  color: #ffffff;
  border-radius: 20px;
  background-color: #f6a623;
}

.dropdown-image-container {
  display: inline-block;

  width: 30px;
  margin-right: 10px;

  text-align: center;
}

.dropdown-image-container img {
  display: inline-block;

  vertical-align: middle;
}

.active-link {
  font-family: "Helvetica Bold", "Arial", "sans-serif";
}

.flex-page-centered {
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
}

.flex-center {
  display: flex;

  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  flex-wrap: wrap;
  justify-content: center;
}

.white-container {
  display: flex;

  width: 100%;
  max-width: 1220px;
  min-height: 600px;
  margin: 20px 0;
  padding: 0;

  border-radius: 5px;
  background-color: #ffffff;

  align-items: stretch;
  -webkit-box-align: stretch;
  justify-content: center;
}

.white-container h1 {
  font-size: 30px;
}

/* Sidebars */
.sidebar {
  z-index: 1;

  width: 239px;
  height: 100vh;

  flex-shrink: 0;
}

.sidebar__nav {
  margin: 0px;
  padding: 35px 0 0;

  list-style: none;

  text-decoration: none;
}

.sidebar__header {
  display: none;
}

.sidebar__item {
  display: block;
}

.sidebar__item .sidebar__link:hover,
.sidebar__item .sidebar__link:focus {
  text-decoration: none;
}

.sidebar__link {
  display: block;

  width: 100%;
  padding: 5px 0;

  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all 0.4s ease;
  text-align: left;

  color: #22bffd;
}

.sidebar__link:hover {
  text-decoration: none;
}

.sidebar__link:focus {
  color: #000000;
}

.sidebar__link--active {
  font-weight: bold;

  color: #000000;
}

.sidebar__link--active:hover {
  color: #000000;
}

.sidebar__icon {
  margin-left: 5px;

  transition: all 0.4s ease-in-out;
}

.sidebar__icon--active {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.sidebar__item--active {
  font-family: "Helvetica Bold", "Arial", "sans-serif";

  color: black;
}

.sidebar__item--active:focus {
  font-family: "Helvetica Bold", "Arial", "sans-serif";

  text-decoration: none;

  color: black;
}

.sidebar__item--active:focus:active {
  font-family: "Helvetica Bold", "Arial", "sans-serif";

  text-decoration: none;

  color: black;
}

.sidebar__item--active:active {
  font-family: "Helvetica Bold", "Arial", "sans-serif";

  color: black;
}

.sidebar-layout {
  display: flex;

  width: 100%;
  max-width: 1480px;
  min-height: calc(100vh - 120px);
  margin: 0 auto;

  align-items: stretch;
  -webkit-box-align: stretch;
  justify-content: flex-start;
}

.sidebar-nav {
  padding: 0 20px;

  list-style-type: none;
}

.sidebar-nav li {
  padding: 7px 0;
}

.sidebar-nav img {
  width: 20px;
  margin-right: 10px;
}

.sidebar-nav a {
  color: #4a4a4a;
}

.sidebar-nav img.sidebar-icon {
  height: 15px;
}

.active-sidebar-link {
  font-family: "Helvetica Bold", "Arial", "sans-serif";

  color: #4a4a4a;
}

.sidebar-with-header h5 {
  font-size: 12px;

  margin: 0 20px 10px 20px;
  padding: 10px;

  border-radius: 3px;
  background-color: #f7f7f7;
}

.sidebar-with-header .sidebar-nav {
  padding: 0 20px 20px 30px;
}

.jobs-sidebar h6 {
  padding: 0 20px;
}

.jobs-sidebar p {
  padding: 0 20px;
}

.jobs-sidebar .sidebar-nav {
  margin-bottom: 20px;
}

.jobs-sidebar .sidebar-nav li {
  padding-left: 10px;
}

.jobs-sidebar .sidebar-progress {
  font-size: 1em;

  text-align: left;
}

.modal-left-sidebar {
  border-right: 1px solid #dbe0e1;
}

.modal-left-sidebar h6 {
  padding: 0 20px;
}

.modal-left-sidebar p {
  padding: 0 20px;
}

.modal-left-sidebar .fixed-height-list li {
  min-width: 200px;
}

.modal-right-sidebar {
  padding: 0 20px;
}

.modal-right-sidebar button {
  margin: 20px 0 30px 0;
}

.layout-left-column {
  z-index: 2;

  display: block;

  width: 360px;
  min-width: 360px;
  height: 100%;
  padding-top: 40px;
  padding-right: 60px;

  background-color: #ffffff;
}

.layout-left-column .manage-tags {
  margin: 0;
  padding: 0;

  color: #4a90e2;
}

.layout-right-column {
  width: calc(100% - 240px);
  margin: 40px 20px 20px 20px;
}

.tag-header {
  display: flex;

  margin-bottom: 20px;

  align-items: center;
  -webkit-box-align: center;
  justify-content: space-between;
}

.tag-header p {
  display: inline-block;

  margin-bottom: 0;
  padding-left: 0;

  vertical-align: middle;
}

.tag-header img {
  display: inline-block;

  width: 30px;
  margin-right: 10px;

  vertical-align: middle;
}

.tag-list {
  position: relative;
}

.tag-list .fixed-height-list {
  overflow-y: scroll;

  height: calc(100vh - 340px);
  min-height: 200px;
}

.tag-list:hover .cover-bar {
  transition: all 0.5s;

  opacity: 0;
}

.tag-list ul {
  padding: 0;

  list-style: none;
}

.tag-list li {
  overflow: hidden;

  width: 100%;
  margin-bottom: 20px;

  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tag-list li:hover {
  font-weight: bold;
}

.tag-list img {
  width: 26px;
  margin-right: 10px;
}

.tag-list:not(.disabled) li:hover img,
.tag-list:not(.disabled) li.selected-tag img {
  opacity: 0.5;
}

.fixed-height-list::-webkit-scrollbar {
  overflow: visible;

  width: 0.4em;

  border-radius: 4px;
}

.fixed-height-list::-webkit-scrollbar-thumb {
  overflow: visible;

  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
}

.cover-bar {
  position: absolute;
  top: 0;
  right: 0;

  width: 0.4em;
  height: 100%;

  transition: all 0.5s;

  opacity: 1;
  background: #ffffff;
}

.manage-tags {
  font-weight: normal;

  margin-top: 20px;
  margin-bottom: 0;

  cursor: pointer;
}

.manage-tags img {
  width: 20px;
  margin-right: 5px;
  margin-bottom: 2px;
}

#manage-tags-modal .modal-header {
  padding: 20px;
}

#manage-tags-modal .modal-footer {
  padding: 20px 20px 30px 20px;
}

#manage-tags-modal .modal-sidebar-container {
  display: flex;

  align-items: stretch;
  -webkit-box-align: stretch;
  justify-content: flex-start;
}

#manage-tags-modal .modal-close {
  margin: 30px auto 0;

  cursor: pointer;

  color: #d2d2d2;
}

.modal-add-tags {
  display: none;
}

.modal-delete-tags {
  display: none;
}

.modal-delete-tags button {
  margin-right: 10px;
}

.required-text {
  font-size: 12px;

  margin-bottom: 20px;
}

.tag-list.disabled li:hover {
  cursor: default;

  background-color: #ffffff;
}

.tag-list.disabled li.selected-tag {
  cursor: default;

  background-color: #ffffff;
}

.manage-tags.disabled {
  cursor: not-allowed;

  color: #dedede !important;
}

.button.disabled {
  cursor: not-allowed;

  color: #dedede !important;
}

.text-link.disabled {
  cursor: not-allowed;

  color: #dedede !important;
}

.search-tags-input {
  padding-right: 20px;
  padding-left: 60px;
}

.search-tags-input .input-group-addon {
  font-size: 12px;

  padding: 8px 10px 5px 10px;
}

.tall-form-control {
  font-size: 14px;
  line-height: 1.42857;

  display: block;

  width: 100%;
  height: 45px;
  padding: 6px 12px;

  color: #555555;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #ffffff none;
}

/* Forms */
.form-control {
  height: 37px;

  transition: none;

  color: #4a4a4a;
  border: 1px solid #ededed;
  border-radius: 5px;
  box-shadow: none;
}

.form-control-loading::after {
  position: absolute;

  display: inline-block;

  width: 16px;
  height: 16px;
  margin-top: 11px;
  margin-left: -25px;

  content: "";
  -webkit-animation: Select-animation-spin 0.4s infinite linear;
          animation: Select-animation-spin 0.4s infinite linear;

  border: 2px solid #cccccc;
  border-right-color: #333333;
  border-radius: 50%;
}

.form-control-loading input {
  display: inline-block;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 2px 0 0;
  font-size: 150%;
}

input::-webkit-input-placeholder {
  color: #cccccc;
}

input::-moz-placeholder {
  color: #cccccc;
}

input:-ms-input-placeholder {
  color: #cccccc;
}

input:-moz-placeholder {
  color: #cccccc;
}

.input-group.input-group-addon-transparent:not(.addon-position-left) input {
  border-right: none;
}

.input-group.input-group-addon-transparent.addon-position-left input {
  border-left: none;
}

.input-group-addon-transparent .input-group-addon {
  border: 1px solid #ededed;
  background-color: transparent !important;
}

.pricesheet-markup-value-right .input-group-addon-transparent .input-group-addon {
  border: 1px solid #ededed;
  border-right: none;
}

.pricesheet-markup-value-left .input-group-addon-transparent .input-group-addon {
  border: 1px solid #ededed;
  border-left: none;
}

.input-group-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  -webkit-appearance: none;
}

label {
  font-family: "Helvetica Bold", "Arial", "sans-serif";
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
}

label .sub-message {
  font-family: "Helvetica Roman", "Arial", "sans-serif";
  font-weight: normal;

  float: right;
}

textarea.no-resize {
  resize: none;
}

select.form-control {
  background-image: url("../images/arrow-select.png");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 9px 6px;

  -webkit-appearance: none;
     -moz-appearance: none;
}

.pricesheet-markup-type .select-caret:after {
  border: 1px solid #ededed;
  border-left: none;
}

.select-caret {
  position: relative;
}

.select-caret:before {
  position: absolute;
  top: 0;
  right: 0;

  width: 30px;
  height: 100%;

  content: "";
  pointer-events: none;

  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #ffffff;
}

.select-caret:after {
  position: absolute;
  top: 0;
  right: 0;

  width: 30px;
  height: 100%;

  content: "";
  pointer-events: none;

  border: 1px solid #ededed;
  background: url("../images/icon_down_caret.png") no-repeat center center;
  background-size: 10px;
}

.select-caret select {
  background: linear-gradient(to right, #ffffff, #ffffff) no-repeat top right;
  background-size: 27px 100%;

  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.select-caret select:focus {
  border-color: #cccccc;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

select::-ms-expand {
  display: none;
}

select option:disabled {
  font-style: italic;

  color: #d1d1d1;
}

div.Select-control {
  height: 37px;

  border-color: #e4e4e4;
  border-radius: 5px;
}

.form-control[readonly] {
  background-color: #ffffff;
}

input[type="submit"] {
  font: inherit;

  padding: 0;

  cursor: pointer;

  color: inherit;
  border: none;
  outline: inherit;
  background: none;
}

input[type="reset"] {
  font: inherit;

  padding: 0;

  cursor: pointer;

  color: inherit;
  border: none;
  outline: inherit;
  background: none;
}

input[disabled] {
  background-color: #f7f7f7;
}

/* Buttons */
.btn:focus,
.btn:active {
  outline: 0 !important;
}

.btn-gray {
  background-color: #dedede;
}

.btn-dark-gray {
  color: #ffffff;
  background-color: #4a4a4a;
}

.btn-dark-gray:hover {
  color: #ffffff;
  background-color: #6b6b6b;
}

.btn-dark-gray:visited {
  color: #ffffff;
}

.btn-dark-gray:active {
  color: #ffffff;
}

.btn-dark-gray:focus {
  color: #ffffff;
  background-color: #6b6b6b;
}

.btn-success {
  color: #ffffff;
  background-color: #00b089;
}

.btn-light-blue {
  color: #ffffff;
  background-color: #48d9fa;
}

button {
  font: inherit;

  padding: 0;

  cursor: pointer;

  color: inherit;
  border: none;
  outline: inherit;
  background: none;
}

/* Sub Navigation */
.subnavigation {
  position: -webkit-sticky;
  position:         sticky;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;

  display: flex;

  width: 100%;
  height: 72px;

  color: #ffffff;
  background: #1b334e;

  -webkit-box-flex: 1;
  flex-flow: row nowrap;
  flex-grow: 1;
}

.subnavigation__container {
  display: flex;

  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
  padding-right: 60px;
  padding-left: 60px;

  -webkit-box-flex: 1;
  -webkit-box-pack: start;
  flex-flow: row nowrap;
  flex-grow: 1;
  justify-content: flex-start;
}

.subnavigation__header {
  display: flex;

  min-width: 250px;
  height: 100%;

  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  flex-shrink: 0;
  justify-content: space-between;
}

.subnavigation__header:nth-child(1) {
  font-weight: bold;
}

.subnavigation__header .subnavigation__item:nth-child(1) {
  font-weight: bold;
}

.subnavigation__header .subnavigation__item {
  display: inline-block;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.subnavigation__header .subnavigation__link:after {
  content: none;
}

.subnavigation__item {
  display: flex;

  margin-left: 22px;
  padding: 0;

  white-space: nowrap;

  align-items: stretch;
  -webkit-box-align: stretch;
}

.subnavigation__item:nth-child(1) {
  margin-left: 0px;
}

.subnavigation__item:hover {
  text-decoration: none;
}

.subnavigation__label {
  white-space: nowrap;
}

.subnavigation__label--disabled {
  opacity: 0.2;
}

.subnavigation__hide-desktop {
  display: none;
}

.subnavigation__list {
  display: flex;

  height: 100%;
  margin: 0;
  margin-left: 55px;
  padding: 0;

  color: #ffffff;
}

.subnavigation__link {
  font-weight: initial;

  position: relative;

  display: flex;
  overflow: visible;

  cursor: pointer;
  transition: -webkit-transform 0.2s ease-in;
  transition:         transform 0.2s ease-in;
  transition:         transform 0.2s ease-in, -webkit-transform 0.2s ease-in;

  color: #ffffff;

  align-items: center;
  -webkit-box-align: center;
}

.subnavigation__link:nth-child(1) {
  margin-left: 0px;
}

.subnavigation__link:after {
  position: absolute;
  bottom: 0px;
  left: 0px;

  display: block;

  width: 100%;
  height: 3px;

  content: "";
  transition: -webkit-transform 0.2s ease-in;
  transition:         transform 0.2s ease-in;
  transition:         transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
  -webkit-transform: scale(0);
          transform: scale(0);

  background-color: #22bffd;
}

.subnavigation__link:focus {
  text-decoration: none;

  color: #ffffff;
}

.subnavigation__link:hover {
  text-decoration: none;

  color: rgba(255, 255, 255, 0.8);
}

.subnavigation__link:hover:after {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

.subnavigation__link--active {
  text-decoration: none;

  color: #ffffff;
}

.subnavigation__link--active:after {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

.subnavigation__link--narrow {
  cursor: pointer;
  white-space: nowrap;

  color: #ffffff;
}

.subnavigation__controllers {
  right: 0;

  display: flex;

  height: 100%;
  margin-left: auto;

  align-items: center;
  -webkit-box-align: center;
}

.subnavigation__controllers .subnavigation__link {
  margin: 0px;
}

.subnavigation__controllers .subnavigation__link:after {
  content: none;
}

.subnavigation__controllers .subnavigation__item {
  display: flex;

  margin-bottom: 0px;
  margin-left: 18px;

  cursor: pointer;

  color: #ffffff;

  align-items: center;
  -webkit-box-align: center;
  flex-wrap: nowrap;
}

.subnavigation__controllers .subnavigation__item:hover {
  text-decoration: none;

  opacity: 0.2;
}

.subnavigation__controllers .subnavigation__item--disabled {
  pointer-events: none;
  color: rgba(255, 255, 255, 0.2);
}

.subnavigation__controllers .subnavigation__item--disabled .subnavigation__link--icon {
  cursor: none;

  opacity: 0.2;
}

.subnavigation__link--icon {
  margin-right: 7px;
}

.statustoggle {
  margin-left: 33px;

  cursor: pointer;

  color: #c6c6c6;
}

.statustoggle__item {
  font-size: 12px;
}

.statustoggle__item--caret {
  margin-left: 5px;

  color: #22bffd;
}

.gray-header {
  position: relative;
  z-index: 4;

  margin-bottom: 40px;
  padding: 15px 20px;

  border-bottom: 1px solid #dbe0e1;
  background-color: #f7f7f7;
}

.gray-header .body-header {
  margin: 0;
}

.gray-header h4 {
  font-size: 18px;
}

.body-header {
  display: flex;

  margin: 30px 0;

  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  flex-wrap: wrap;
  justify-content: space-between;
}

.body-second-header {
  display: flex;

  margin: 30px 0;

  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  flex-wrap: wrap;
  justify-content: space-between;
}

.body-second-header h3 {
  margin-top: 0;
}

.icon-action-list {
  display: flex;

  -webkit-animation: fadein 0.4s;
          animation: fadein 0.4s;

  align-items: center;
  -webkit-box-align: center;
  justify-content: flex-start;
}

.icon-action-list a:hover {
  opacity: 1;
}

.icon-action-btn {
  margin-left: 8px;

  cursor: pointer;
}

.icon-action-btn__image {
  width: 37px;
  height: 37px;
}

.action-btn {
  width: 36px;
  height: 36px;
  margin-left: 5px;
  padding: 8px 4px;

  cursor: pointer;
  text-align: center;

  border-radius: 5px;
  background-color: #f7f7f7;
}

.action-btn:hover {
  background-color: #dedede;
}

.action-btn img {
  height: 100%;
}

.action-btn.blue-action-btn {
  background-color: #22bffd;
}

.action-btn.blue-action-btn:hover {
  background-color: #a8b7c7;
}

.default-content {
  margin-top: 30px;
}

.edit-content {
  display: none;

  margin-top: 30px;
}

.edit-action-list select {
  display: inline-block;

  width: auto;
  min-width: 80px;
  margin: 5px 0;
}

.edit-action-list button {
  margin: 5px 0 5px 5px;
}

.edit-action-list button img {
  height: 23px;
}

.edit-action-list p {
  display: inline-block;

  margin: 5px 0 5px 20px;
}

.trash-after-table {
  margin-top: 10px;
}

.no-content-row {
  display: table-row;
}

.no-content-row td {
  font-family: "Helvetica Roman", "Arial", "sans-serif";
  font-weight: normal;

  text-align: center;
}

.no-content-row td p {
  font-family: "Helvetica Roman", "Arial", "sans-serif";
  font-weight: normal;

  text-align: center;
}

.no-content-row td button {
  display: block;

  margin: 0 auto;
}

.color-border {
  width: 100%;
  height: 2px;

  border-radius: 100px;
}

.blue-border {
  background-color: #22bffd;
}

.yellow-border {
  background-color: #f9ce00;
}

.purple-border {
  background-color: #c86dd7;
}

.green-border {
  background-color: #7ed321;
}

.btn-primary {
  border-color: #22bffd;
  background-color: #22bffd;
}

.modal {
  padding: 0 !important;

  text-align: center;
}

.modal:before {
  display: inline-block;

  height: 100%;
  margin-right: -4px;

  content: "";
  vertical-align: middle;
}

.modal-content {
  padding: 0 20px 30px 20px;
}

.modal-dialog {
  display: inline-block;

  text-align: left;
  vertical-align: middle;
}

.modal-header {
  position: relative;

  border: none;
}

.modal-header .center-header-text {
  text-align: center;
}

.modal-header .right-text {
  cursor: pointer;
  text-align: right !important;
}

.modal-header .pull-left {
  cursor: pointer;

  color: #999999;
}

.modal-footer {
  display: flex;
  flex-direction: column;

  border: none;
}

.modal-footer button {
  min-width: 167px;
}

.modal-footer-center-text {
  margin-top: 20px;
  margin-bottom: 0;

  text-align: center;

  color: #999999;
}

.modal-close {
  margin: 30px auto 0;

  cursor: pointer;

  color: #d2d2d2;
}

.text-success {
  color: #6ad200;
}

.pills {
  display: inline-block;

  margin: 0;
  padding: 3px 25px 2px 25px;

  text-align: center;

  border-radius: 50px;
}

.draft-pill {
  color: #ffffff;
  background-color: #c6c6c6;
}

.expired-pill {
  padding: 3px 20px 2px 20px;

  color: #ffffff;
  background-color: #000000;
}

.pre-sale-pill {
  color: #000000;
  background-color: #f8d31c;
}

.selling-pill {
  color: #ffffff;
  background-color: #22bffd;
}

.no-content-container {
  padding: 100px 10px;

  text-align: center;

  color: #999999;
}

.photo-grid {
  display: flex;
  display: -ms-grid;
  display:     grid;

  flex-flow: row wrap;
  grid-gap: 10px;
  -ms-grid-columns: (179px)[auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(179px, .3fr));
}

.photo-container {
  position: relative;
  width: 100%;
  padding-top: 149%;

  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.image-container {
  position: absolute;
  top: 0;

  display: flex;
  overflow: hidden;

  width: 100%;
  height: 100%;

  background-color: #000000;

  align-items: center;
  -webkit-box-align: center;
}

.image-container .photo-container__image {
  display: block;

  width: 100%;
  height: auto;
}

.image-container .photo-container__caption {
  font-size: 13px;

  position: absolute;
  right: 0;
  bottom: 0px;
  left: 0;

  display: block;
  overflow: hidden;

  width: 100%;
  padding: 11px 10px;

  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.8);
}

.image-container .photo-container__caption--tag {
  padding-left: 40px;
}

.image-container img.crosshair-icon {
  position: absolute;
  top: 5px;
  left: 5px;

  display: none;

  width: 30px;
}

.image-container img.has-tags {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  left: 10px;

  width: 24px;
}

.image-container img.added-image {
  position: absolute;
  right: 5px;
  bottom: 10px;

  width: 20px;
}

.active-photo-container {
  border: 3px solid #22bffd;
}

.switch-container {
  display: flex;

  align-items: center;
  -webkit-box-align: center;
  justify-content: flex-start;
}

.switch-container p {
  margin: 0 0 5px 10px;
}

.cmn-toggle {
  position: absolute;

  visibility: hidden;

  margin-left: -9999px;
}

.cmn-toggle + label {
  position: relative;

  display: block;

  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  outline: none;
}

input.cmn-toggle-round + label {
  width: 50px;
  height: 32px;
  padding: 2px;

  border-radius: 60px;
}

input.cmn-toggle-round + label:before {
  position: absolute;
  top: 2px;
  right: 1px;
  bottom: 2px;
  left: 2px;

  display: block;

  content: "";
  transition: background 0.4s;

  border-radius: 30px;
  background-color: #f1f1f1;
}

input.cmn-toggle-round + label:after {
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;

  display: block;

  width: 28px;

  content: "";
  transition: margin 0.4s;

  border-radius: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

input.cmn-toggle-round:checked + label:before {
  background-color: #8ce196;
}

input.cmn-toggle-round:checked + label:after {
  margin-left: 19px;
}

.bottom-flex {
  display: flex;

  align-items: flex-end;
  -webkit-box-align: end;
  -webkit-box-pack: justify;
  flex-wrap: wrap;
  justify-content: space-between;
}

.skip-duplicates-field {
  width: 200px;
  margin-bottom: 10px;
}

.bubble {
  display: inline-block;

  width: 6px;
  height: 6px;
  margin: 0 1px;

  -webkit-animation: expand 0.75s ease-in-out infinite;
          animation: expand 0.75s ease-in-out infinite;

  border-radius: 40px;
  background: #f5f5f5;
}

.bubble:nth-child(2) {
  -webkit-animation-delay: 180ms;
          animation-delay: 180ms;
}

.bubble:nth-child(3) {
  -webkit-animation-delay: 360ms;
          animation-delay: 360ms;
}

.bubble-loader {
  margin-right: 10px;
}

.search-and-btn {
  display: flex;

  align-items: center;
  -webkit-box-align: center;
  justify-content: flex-start;
}

.search-and-btn .btn-primary {
  margin-top: 0;
}

.nav-search-field {
  width: 200px;
  margin-right: 10px;
}

.nav-search-field .input-group-addon {
  background-color: #ffffff !important;
}

.pagination-centered {
  text-align: center;
}

.text-link {
  cursor: pointer;

  color: #22bffd;
}

.text-link-group-addon {
  cursor: pointer;

  color: #4a90e2;
}

.gray-text {
  color: #a8b7c7;
}

.back-to-gallery {
  margin-top: 20px;
}

.tip-container {
  margin-top: 10px;
}

.tip-align-top .badge-tip {
  vertical-align: top;
}

.tip-align-top .tip-text {
  vertical-align: top;
}

.tip-align-center .badge-tip {
  vertical-align: middle;
}

.tip-align-center .tip-text {
  vertical-align: middle;
}

.badge-tip {
  display: inline-block;

  width: 30px;
  height: 30px;
  margin-right: 5px;

  border-radius: 40px;
  background-color: #fec35d;
}

.badge-tip img {
  width: 30px;
  padding: 5px;
}

.tip-text {
  font-size: 12px;

  display: inline-block;

  width: calc(100% - 50px);
  margin-bottom: 0;

  color: #6b6b6b;
}

.valid-until {
  font-size: 12px;
  font-weight: normal;

  display: block;

  width: 100%;
  margin-top: -10px;

  text-align: right;

  color: #a59c9c;
}

#delete-modal .modal-body p {
  margin-bottom: 20px;

  text-align: center;
}

#delete-modal input {
  width: 200px;
  margin: 0 auto;
}

.blue-gradient {
  background: linear-gradient(135deg, #2a2c55 0%, #4f8ad2 100%);

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2a2c55", endColorstr="#4f8ad2",GradientType=1);
}

.orange-gradient {
  background: linear-gradient(135deg, #fa6161 0%, #f5a623 100%);

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FA6161", endColorstr="#F5A623",GradientType=1);
}

.onboarding-bg {
  background-color: #f7f7f7;
}

.onboarding-bg .gray-logo {
  display: block;

  width: 115px;
  margin: 0 auto;
  padding-top: 20px;
}

.onboarding-bg .bottom-links {
  display: flex;

  align-items: flex-start;
  -webkit-box-align: start;
  justify-content: space-between;
}

.onboarding-bg .bottom-links p {
  padding: 10px;

  color: #a1a1a1;
}

.onboarding-bg .bottom-links a {
  padding: 10px;

  color: #a1a1a1;
}

.onboarding-bg .bottom-links a:visited {
  padding: 10px;

  color: #a1a1a1;
}

.onboarding-bg .bottom-links a:active {
  padding: 10px;

  color: #a1a1a1;
}

.left-onboarding-container {
  display: flex;
  flex-direction: column;

  width: 50%;
  padding: 0;

  text-align: center;

  color: #ffffff;
  border-radius: 5px 0 0 5px;

  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
}

.left-onboarding-container img {
  width: 195px;
  margin-bottom: 40px;
}

.right-onboarding-container {
  width: 50%;
}

.applied-filters-container .applied-filters-header {
  display: flex;

  align-items: baseline;
  -webkit-box-align: baseline;
  justify-content: flex-start;
}

.applied-filters-container .applied-filters-header h5 {
  margin-right: 20px;
}

.applied-filters-container .applied-filters-header .text-link {
  cursor: pointer;
}

.applied-filters-container .applied-filters-list {
  display: flex;

  align-items: center;
  -webkit-box-align: center;
  justify-content: flex-start;
}

.applied-filters-container .applied-filters-tag {
  font-family: "Helvetica Roman", "Arial", "sans-serif";

  display: flex;

  padding: 3px 8px;

  color: #ffffff;
  border-radius: 5px;
  background-color: #f28821;

  align-items: center;
  -webkit-box-align: center;
  justify-content: flex-start;
}

.applied-filters-container .applied-filters-tag p {
  margin: 0 5px 0 0;
}

.applied-filters-container .applied-filters-tag .fa-minus {
  font-size: 8px;

  padding: 2px 3px;

  cursor: pointer;

  border: 1px solid #ffffff;
  border-radius: 50px;
}

.applied-filters-container .applied-filters-image {
  position: relative;

  width: 35px;
  height: 35px;
}

.applied-filters-container .applied-filters-image img {
  width: 100%;

  border-radius: 50px;
}

.applied-filters-container .applied-filters-image .fa-minus-circle {
  font-size: 15px;

  position: absolute;
  top: -2px;
  right: -7px;

  cursor: pointer;

  color: #ff0000;
}

.applied-filters-container .applied-filters-item {
  margin-right: 10px;
}

.no-applied-filters {
  display: none;
}

.Select-menu-outer {
  z-index: 4 !important;
}

.tippy-tooltip {
  background-color: #1f344d !important;
}

.tippy-popper[x-placement^="top"] [x-arrow] {
  border-top-color: #1f344d !important;
}

.tippy-popper[x-placement^="bottom"] [x-arrow] {
  border-bottom-color: #1f344d !important;
}

.center {
  text-align: center;
}

.DayPicker-Day {
  width: 20px !important;
  height: 20px !important;
  padding: 4px 5px 2px !important;
}

.hidden {
  display: none;
}

.full-width {
  width: 100%;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #ffffff !important;
  background-color: #63c5f9;
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

:focus {
  outline: none;
}

/* Tables */
.table-container table {
  border-spacing: 1px;
  border-collapse: separate;

  border: 1px solid #e8e8e8;
  border-radius: 6px;
}

.table-container table th {
  padding: 25px 15px;
}

.table-container table tr:nth-child(even) {
  background-color: #f7f7f7;
}

.table-container table td {
  padding: 15px;
}

.table-container table .table-header-border {
  padding: 0 10px;

  background-color: #ffffff;
}

.table-container table .no-content-row {
  background-color: #ffffff;
}

.table-container table .no-content-row td {
  padding: 60px 0 60px 0;
}

.table-container table .no-content-row:nth-child(even) {
  background-color: #ffffff;
}

.table-container td.actions {
  white-space: nowrap;
}

.table-container tr:last-child td:first-of-type {
  border-bottom-left-radius: 5px;
}

.table-container tr:last-child td:last-of-type {
  border-bottom-right-radius: 5px;
}

.table-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #ffffff;
}

.table-header h4 {
  font-size: 16px;
  font-weight: bold;

  margin: 0;
}

.table-header th:first-of-type {
  border-top-left-radius: 5px;
}

.table-header th:last-of-type {
  border-top-right-radius: 5px;
}

.table-header a {
  color: #333333;
}

.table-icon {
  width: 24px;
}

.table-container.requesting table thead th {
  cursor: not-allowed !important;

  opacity: 0.9;
}

.table-selector {
  display: flex;

  margin: 20px 0;

  align-items: center;
  -webkit-box-align: center;
       -o-box-align: center;
  box-flex: 1;
  -ms-flex-align: center;
  flex-flow: row wrap;
  flex-grow: 1;
}

.table-selector__list {
  display: block;
  overflow-y: scroll;

  width: 300px;
  height: 228px;
  margin: 0;
  padding: 0;

  list-style: none;

  border: 1px solid #e8e8e8;
  border-radius: 5px;
}

.table-selector__list:first-child {
  margin-right: 20px;
}

.table-selector__list:last-child {
  margin-left: 20px;
}

.table-selector__list:active,
.table-selector__list:focus {
  outline: 0;
}

.table-selector__item {
  display: block;

  width: 100%;
  padding: 8px 16px;

  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all 0.2s ease;
  -webkit-animation: fadein 0.4s;
          animation: fadein 0.4s;
}

.table-selector__item:nth-child(even) {
  background-color: #f7f7f7;
}

.table-selector__item.table-selector__item--active {
  color: #ffffff;
  background-color: #22bffd;
}

.table-selector__button {
  display: block;

  width: 30px;
  height: 30px;

  background-image: url("../images/icon-arrow-white-right.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 13px;
}

.table-selector__button:focus {
  outline: 0;
}

.table {
  width: 100%;

  table-layout: auto;
  border-collapse: separate;

  border: 2px solid #e8e8e8;
  border-radius: 5px;
  outline: 12px solid #ffffff;
  outline-offset: -14px;
}

.table .table__header {
  display: contents;
}

.table .table__header tr th {
  font-family: "Helvetica Bold";

  display: table-cell;

  padding: 20px 18px;

  text-align: left;

  color: inherit;
  border-bottom: 5px solid #f1637e;
}

.table .table__body tr:nth-child(even) {
  background-color: #f7f7f7;
}

.table .table__body tr td,
.table .table__footer tr td {
  display: table-cell;

  padding: 17px 18px;

  border-top: 0;
}

.table-dynamic {
  display: flex;

  width: 100%;
  min-width: 940px;
  margin-bottom: 40px;

  -webkit-box-flex: 1;
  flex-flow: column wrap;
  flex-grow: 1;
}

.table-dynamic__header {
  font-family: "Helvetica Bold";
  font-size: 12px;
  line-height: 1;

  display: flex;

  margin: 0;
  padding: 20px 20px 20px 0;

  list-style: none;

  border-bottom: 3px solid #fed200;

  -webkit-box-flex: 1;
  -webkit-box-pack: justify;
  flex-flow: row nowrap;
  flex-grow: 1;
  justify-content: space-between;
}

.table-dynamic__row {
  line-height: 1;

  position: relative;

  display: flex;
  overflow: hidden;

  max-height: 42px;
  margin: 0 0 26px;
  padding: 26px 20px 0 0;

  list-style: none;

  cursor: pointer;
  transition: all 0.4s ease-in-out;

  -webkit-box-flex: 1;
  -webkit-box-pack: justify;
  flex-flow: row nowrap;
  flex-grow: 1;
  justify-content: space-between;
}

.table-dynamic__row:not(:nth-child(2)) {
  border-top: 1px solid #dbe0e1;
}

.table-dynamic__row::after {
  position: absolute;
  right: 10px;

  width: 10px;
  height: 14px;

  content: "";
  transition: all 0.4s ease;

  background: no-repeat center;
  background-image: url("../images/arrow-down-small.png");
  background-size: contain;
}

.table-dynamic__row.table-dynamic__row--disabled {
  cursor: auto;
}

.table-dynamic__row.table-dynamic__row--disabled::after {
  display: none;
}

.table-dynamic__row.table-dynamic__row--active {
  font-weight: bold;

  max-height: 200px;

  border-top-width: 3px;
}

.table-dynamic__row.table-dynamic__row--active + .table-dynamic__row:not(.table-dynamic__row--active) {
  border-top-width: 3px;
}

.table-dynamic__row.table-dynamic__row--active::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.table-dynamic__cell {
  padding: 0 10px;

  white-space: nowrap;

  -webkit-box-flex: 1;
  flex-basis: 0;
  flex-grow: 1;
}

.table-dynamic__link {
  font-size: 12px;
  font-style: normal;

  color: #3e90e9;
}

.table-dynamic__percentage {
  font-size: 12px;
  font-style: normal;

  color: #a8a8a8;
}

.table-dynamic__details {
  font-size: 12px;

  display: flex;

  margin: 10px 0 0;

  -webkit-box-flex: 1;
  flex-flow: row wrap;
  flex-grow: 1;
}

.table-dynamic__details dt,
.table-dynamic__details dd {
  font-weight: normal;

  padding: 2px;
}

.table-dynamic__details dt {
  width: 35%;
  max-width: 35%;

  text-align: right;
}

.table-dynamic__details dd {
  width: 65%;
  max-width: 65%;

  color: #969696;
}

.table-dynamic__details--secondary dt,
.table-dynamic__details--secondary dd {
  width: 100%;
  max-width: 100%;
  padding: 0;

  text-align: left;

  color: inherit;
}

.table-dynamic__details--secondary dt {
  font-family: "Helvetica Bold";
  font-size: 10px;

  text-transform: uppercase;

  color: #969696;
}

.table-dynamic__details--secondary dd {
  margin-bottom: 10px;
}

.slider.slider-horizontal {
  width: 100% !important;
}

.error-boundary {
  line-height: 26px;

  display: flex;
  flex-direction: column;

  margin: 60px;
  padding: 60px;

  text-align: center;

  border: 1px dashed #cccccc;

  align-items: center;
  justify-content: center;
}

.error-boundary__image {
  padding: 20px 0;
}

.error-boundary__link {
  color: #22bffd;
}

/* Media Queries */
@media only screen and (max-width: 1024px) {
  .subnavigation__label {
    display: none;
  }

  .container {
    width: auto;
  }
 }

@media only screen and (max-width: 992px) {
  #nav {
    position: static;

    width: 100%;
  }

  .container {
    width: auto;
  }
}

@media only screen and (max-width: 830px) {
  .white-container {
    flex-direction: column;

    height: auto;
    margin: 20px 0;

    align-items: center;
    -webkit-box-align: center;
    justify-content: center;
  }

  .left-onboarding-container {
    width: 100%;
    padding: 40px 10px;

    border-radius: 5px 5px 0 0;
  }

  .left-onboarding-container .responsive-sidebar {
    display: block;
  }

  .right-onboarding-container {
    width: 100%;
  }

  .table-selector {
    flex-direction: column;
  }

  .table-selector__list {
    width: 100%;
    margin: 0;
  }

  .table-selector__list:first-child {
    margin-right: 0;
  }

  .table-selector__list:last-child {
    margin-left: 0;
  }

  .table-selector__button {
    margin: 10px auto;
  }

  .table {
    width: 100%;
    margin-bottom: 100px;

    border-collapse: collapse;
  }

  .table .table__header {
    display: none;
  }

  .table .table__body tr td {
    font-size: 12px;

    position: relative;

    display: block;

    padding: 5px 18px;
  }

  .table .table__body tr td::before {
    font-family: "Helvetica Bold", "Arial", "sans-serif";
    font-size: 14px;
    font-weight: bold;

    position: relative;

    display: block;

    margin-right: 4px;

    content: attr(headers) ":";
  }

  .table .table__body tr td:first-child {
    padding-top: 18px;
  }

  .table .table__body tr td:last-child {
    padding-bottom: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: auto;
    padding: 0 15px;
  }

  .subnavigation {
    position: absolute;
    top: 0;

    display: flex;
    flex-direction: column;

    min-height: 60px;
    padding-top: 108px;
  }

  .subnavigation__container {
    position: absolute;

    display: flex;
    flex-direction: column;

    height: 107px;
    padding: 15px 0;

    transition: all 0.4s ease-in-out;
    -webkit-transform: translateY(-370px);
            transform: translateY(-370px);

    background-color: #1b334e;

    justify-content: flex-start;
  }

  .subnavigation__container .subnavigation__item:hover {
    color: #22bffd;
  }

  .subnavigation__container .subnavigation__item:hover .subnavigation__link {
    font-weight: bold;

    color: #22bffd;
  }

  .subnavigation__container--active {
    position: fixed;

    height: 100vh;

    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  .subnavigation__header {
    position: absolute;
    z-index: 2;
    top: 48px;
    right: 0;
    left: 0;

    display: flex;

    height: 60px;
    padding: 0 15px;

    background-color: #1b334e;

    justify-content: space-between;
  }

  .subnavigation__header .subnavigation__item {
    width: 70%;
    height: initial;
  }

  .subnavigation__header .subnavigation__item:hover {
    color: #ffffff;
  }

  .subnavigation__header .fa {
    color: #22bffd;
  }

  .subnavigation .subnavigation__header--tall {
    position: absolute;
    z-index: 2;
    top: 48px;
    right: 0;
    left: 0;

    display: flex;
    flex-direction: row;

    min-height: 60px;
    padding: 0 15px;

    background-color: #1b334e;

    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .subnavigation .subnavigation__header--tall .subnavigation__item {
    display: flex;

    width: auto;
    height: 35px;
    margin: 10px 0;

    align-items: center;
    -webkit-box-align: center;
  }

  .subnavigation__header--active {
    position: fixed;
  }

  .subnavigation__list {
    flex-direction: column;

    height: auto;
    margin: 0;
    padding: 0 15px;

    transition: all 0.4s ease-in-out;

    align-items: flex-start;
    -webkit-box-align: start;
  }

  .subnavigation__controllers {
    flex-direction: column;

    height: auto;
    margin: 20px 0 0;
    padding: 0 15px;

    transition: all 0.4s ease-in-out;

    align-items: flex-start;
    -webkit-box-align: start;
    justify-content: flex-start;
  }

  .subnavigation__controllers .subnavigation__item {
    margin: 0;

    cursor: pointer;
  }

  .subnavigation__controllers .subnavigation__item:hover {
    opacity: 1;
    color: #22bffd;
  }

  .subnavigation__controllers--active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  .subnavigation__item {
    width: 100%;
    height: 37px;
    margin: 0;
  }

  .subnavigation__item:after {
    content: none;
  }

  .subnavigation__item:hover {
    color: #22bffd;
  }

  .subnavigation__item:hover .subnavigation__link {
    font-weight: bold;

    color: #22bffd;
  }

  .subnavigation__link {
    width: 100%;

    color: #ffffff;
  }

  .subnavigation__link:hover {
    color: #22bffd;
  }

  .subnavigation__link:after {
    content: none;
  }

  .subnavigation__link--active {
    font-weight: bold;
  }

  .subnavigation__link--active:after {
    content: none;
  }

  .subnavigation__link--icon {
    display: none;
  }

  .subnavigation__label {
    display: block;
  }

  .subnavigation__hide-mobile {
    display: none;
  }

  .sidebar {
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;

    display: block;

    width: 100%;
    height: auto;
    padding: 0;

    cursor: pointer;
    text-align: center;

    border: none;
  }

  .sidebar--single-topnav {
    margin-top: -60px;
  }

  .sidebar__header {
    position: relative;
    z-index: 1;

    display: flex;

    width: 100%;
    height: 60px;
    padding: 10px 0;

    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    border-bottom: solid 1px #dbe0e1;
    background-color: #ffffff;

    align-items: center;
    -webkit-box-align: center;
    justify-content: center;
  }

  .sidebar__nav {
    position: absolute;
    top: 0;

    overflow: hidden;

    width: 100%;
    margin: 0;
    padding: 0px;

    transition: all 0.4s ease-in-out;
    -webkit-transform: translateY(calc(-100% + 56px));
            transform: translateY(calc(-100% + 56px));

    border-bottom: 1px solid #cccccc;
    background-color: #ffffff;
  }

  .sidebar__nav a {
    display: block;

    padding: 8px 0;

    color: #22bffd;
  }

  .sidebar__nav .sidebar__link--active {
    color: #000000;
  }

  .sidebar__nav--active {
    transition: all 0.4s ease-in-out;
    -webkit-transform: translateY(60px);
            transform: translateY(60px);
  }

  .sidebar__item {
    margin: 0;
    padding: 8px 0;

    cursor: pointer;

    color: #4a4a4a;
  }

  .sidebar__item:hover {
    color: #4a4a4a;
    background-color: #f3f4f6;
  }

  .sidebar__link {
    text-align: center;
  }

  .sidebar__link--active {
    font-weight: bold;

    color: #000000;
  }

  .sidebar__link--active:hover {
    color: #000000;
  }

  .nav-content ul {
    display: none;
  }

  .flex-2-column .form-group {
    width: 100%;
  }

  .table-selector__button--active {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }

  .photo-grid {
    -ms-grid-columns: (minmax(150px, .35fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(150px, .35fr));
  }
}

@media only screen and (max-width: 600px) {
  thead {
    display: none;
  }

  .responsive-subnav {
    display: block;
  }

  .subnav {
    display: none;
  }

  .responsive-subnav-links {
    visibility: visible;

    height: auto;
  }

  .onboarding-bg .bottom-links {
    text-align: center;

    -webkit-box-pack: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .onboarding-bg .bottom-links p {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .table-selector {
    flex-direction: column;
  }

  .table-selector__list {
    width: 100%;
    margin: 0;
  }

  .table-selector__list:first-child {
    margin-right: 0;
  }

  .table-selector__list:last-child {
    margin-left: 0;
  }

  .table-selector__button {
    margin: 10px auto;
  }

  .table {
    width: 100%;

    border-collapse: collapse;
  }

  .table .table__header {
    display: none;
  }

  .table .table__body tr td {
    position: relative;

    display: block;
  }

  .table .table__body tr td::before {
    font-weight: bold;

    position: relative;

    display: block;

    margin-right: 4px;

    content: attr(headers) ":";
  }
}

@media only screen and (max-width: 425px) {
  .table-selector {
    flex-direction: column;
  }

  .table-selector__list {
    width: 100%;
    margin: 0;
  }

  .table-selector__list:first-child {
    margin-right: 0;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .table-selector__list:last-child {
    margin-left: 0;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .table-selector__button {
    margin: 10px auto;
  }

  .table {
    width: 100%;

    border-collapse: separate;
  }

  .table .table__header {
    display: none;
  }

  .table .table__body tr td {
    font-size: 12px;

    position: relative;

    display: block;

    padding: 5px 18px;
  }

  .table .table__body tr td::before {
    font-family: "Helvetica Bold", "Arial", "sans-serif";
    font-size: 14px;
    font-weight: bold;

    position: relative;

    display: block;

    margin-right: 4px;

    content: attr(headers) ":";
  }

  .table .table__body tr td:first-child {
    padding-top: 18px;
  }

  .table .table__body tr td:last-child {
    padding-bottom: 18px;
  }

  .table-dynamic__row.table-dynamic__row--active {
    max-height: 300px;
  }

  .table-dynamic__details dt,
  .table-dynamic__details dd {
    width: 100%;
    max-width: 100%;

    text-align: left;
  }

  .modal-dialog {
    width: 100vw;
    height: 100vh;
  }

  .modal-content {
    overflow-y: auto;

    width: 100vw;
    height: 100vh;
    padding-top: 20%;
  }

  .modal-header .center-header-text {
    font-weight: bold;
  }

  .error-boundary {
    margin: 70px 20px 20px;
    padding: 20px;
  }

  .error-boundary__image {
    width: 90%;
    height: auto;
  }

  .photo-grid {
    -ms-grid-columns: (135px)[auto-fit];
    grid-gap: 5px;
    -ms-grid-columns: (minmax(135px, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) and (max-height: 411px) {
  .subnavigation__container {
    flex-direction: row;

    height: 80%;
  }

  .subnavigation__controllers {
    margin: 0;
    padding-left: 40px;
  }

  .subnavigation__controllers .subnavigation__item {
    margin-left: 55px;
  }

  .subnavigation__list {
    height: 90%;
    padding-bottom: 20px;

    flex-wrap: wrap;
  }

 .subnavigation__container--active .subnavigation__list .subnavigation__item {
    width: 100%;
    padding-right: 55px;
  }
}
