#dashboard .QuickGlanceTables {
  display: flex;

  width: 100%;
  margin-top: 40px;
  padding-bottom: 30px;

  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}

#dashboard .QuickGlanceTables .editable-table {
}

#dashboard .QuickGlanceTables .table-header img {
  width: 38px;
}

#dashboard .QuickGlanceTables .table-container {
  width: 32%;
  min-height: 100%;

  border-collapse: separate;

  border: 1px solid #dedede;
  border-radius: 6px;
}

#dashboard .QuickGlanceTables .table-container table {
  width: 100%;

  border: none;
}

#dashboard .QuickGlanceTables .table-container table .all {
  padding-right: 15px;
}

#dashboard .QuickGlanceTables .table-container table td {
  border: none;
}

#dashboard .QuickGlanceTables .table-container table th {
  border: none;
}

#dashboard .QuickGlanceTables table td:last-child {
  min-width: 82px;

  text-align: right;
}

#dashboard .QuickGlanceTables table th:last-child {
  min-width: 82px;

  text-align: right;
}

#dashboard .QuickGlanceTables thead {
  display: table-header-group;
}

#dashboard .current-jobs-table table td:first-child {
  font-family: "Helvetica Bold", "Arial", "sans-serif";
}

#dashboard .latest-orders-table table td:first-child {
  font-family: "Helvetica Bold", "Arial", "sans-serif";
}

#dashboard .table-container table .no-content-row td {
  padding: 80px 0 100px 0;
}

#dashboard .table-container table .jobs-empty td {
  padding: 90px 0 90px 0;
}

#dashboard .table-container table .orders-empty td {
  padding: 90px 0 90px 0;
}

#dashboard .dashboard-modules {
  display: flex;

  padding-top: 30px;
  padding-bottom: 100px;

  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}

#dashboard .module-container {
  display: flex;
  flex-direction: column;

  width: 32%;
  margin-bottom: 20px;
  padding: 20px 10px 10px 10px;

  border-collapse: separate;

  text-align: center;

  border: 1px solid #dedede;
  border-radius: 6px;
  background-color: #ffffff;

  align-items: center;
  justify-content: space-between;
}

#dashboard .module-container img {
  height: 63px;
}

#dashboard .module-container:last-child img {
  padding: 5px 0;
}

#dashboard .module-container button {
  margin-top: 10px;

  color: #333333;
  border: 1px solid #e6e6e6;
  background-color: #f7f7f7;
}

#dashboard .module-container button:active {
  margin-top: 10px;

  color: #333333;
  border: 1px solid #e6e6e6;
  background-color: #f7f7f7;
}

@media only screen and (max-width: 992px) {
  #dashboard .QuickGlanceTables .table-container {
    width: 49%;
    margin-bottom: 20px;
  }

  #dashboard .module-container {
    width: 49%;
  }
}

@media only screen and (max-width: 600px) {
  #dashboard .QuickGlanceTables {
    flex-direction: column;
  }

  #dashboard .QuickGlanceTables .table-container {
    width: 100%;
    min-height: auto;
  }

  #dashboard .module-container {
    width: 100%;
  }
}
